import * as React from "react";
import { useUser } from "../UserProvider";

const Context = React.createContext<{
  isPopupOpened: boolean;
  closePopup: () => void;
}>(undefined as any);

export function DailyActivityRewardProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const { user } = useUser();

  const [isPopupOpened, setIsPopupOpened] = React.useState(
    !user.hasLoginsToday
  );
  const closePopup = () => setIsPopupOpened(false);

  return (
    <Context.Provider
      value={{
        isPopupOpened,
        closePopup,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useDailyActivityRewards() {
  return React.useContext(Context);
}
