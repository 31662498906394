import * as React from "react";
import { useTranslation } from "react-i18next";
import { AbsoluteCenter } from "../AbsoluteCenter";
import { ButtonWithSound } from "../AudioProvider";
import { useQuery } from "../../hooks/useQuery";
import { useDailyActivityRewards } from ".";
import { useScreenType } from "../../hooks/useScreenType";
import {
  DailyActivityRewardsQuery,
  DailyActivityRewardsQueryVariables,
} from "./graphql/DailyActivityRewardsQuery.generated";
import { loader } from "graphql.macro";
import styles from "./DailyActivityRewardPopup.module.scss";
import { CurrencyImage } from "../CurrencyImage";
import { Currency } from "../../graphql/generated";
import CompletedImg from "./completed.png";
import { DailyActivityRewardItemFragmentFragment } from "../../graphql/DailyActivityRewardItemFragment.generated";

const DAILY_ACTIVITY_REWARDS_QUERY = loader(
  "./graphql/DailyActivityRewardsQuery.graphql"
);

const PopupContainer = ({ children }: { children?: React.ReactNode }) => {
  const screenType = useScreenType();

  return (
    <div className={`absolute top-0 left-0 right-0 bottom-0 z-50`}>
      {screenType === "DESKTOP" ? (
        <AbsoluteCenter>{children}</AbsoluteCenter>
      ) : (
        <div className="flex justify-center items-center h-screen">
          {children}
        </div>
      )}
    </div>
  );
};

const Reward = ({
  currency,
  amount,
}: {
  currency: Currency;
  amount: number;
}) => {
  return (
    <div className="mt-2 px-8 flex items-left space-x-2">
      <CurrencyImage currency={currency} width={25} height={25} />
      <span>{amount}</span>
    </div>
  );
};

const Rewards = ({
  item,
  idx,
}: {
  item: DailyActivityRewardItemFragmentFragment;
  idx: number;
}) => {
  const { t } = useTranslation();
  return (
    <div key={item.dayNumber + idx} className={styles.configContainer}>
      <div className="text-white">
        <h2 className="text-center w-full text-xxl font-bold">
          {t("daily_activity_rewards_popup.day_number", {
            number: item.dayNumber,
          })}
        </h2>
        {item.paid ? (
          <img
            src={CompletedImg}
            className={styles.completed}
            width={73}
            height={76}
          />
        ) : (
          <>
            <Reward
              currency={Currency.BlueChips}
              amount={item.config.blueChips}
            />
            <Reward
              currency={Currency.GoldChips}
              amount={item.config.goldChips}
            />
            <Reward
              currency={Currency.RewardPoints}
              amount={item.config.rewardPoints}
            />
          </>
        )}
      </div>
    </div>
  );
};

const Popup = () => {
  const { t } = useTranslation();
  const { closePopup } = useDailyActivityRewards();
  const [{ fetching, data }] = useQuery<
    DailyActivityRewardsQuery,
    DailyActivityRewardsQueryVariables
  >({
    query: DAILY_ACTIVITY_REWARDS_QUERY,
  });

  React.useEffect(() => {
    if (!fetching && data?.dailyActivityRewards?.length === 0) {
      closePopup();
    }
  }, [fetching, data]);

  const totalPaid = React.useMemo(() => {
    if (data?.dailyActivityRewards) {
      const totals = data.dailyActivityRewards.reduce(
        (acc, item) => {
          acc.blueChips += item.paid?.blueChips || 0;
          acc.goldChips += item.paid?.goldChips || 0;
          acc.rewardPoints += item.paid?.rewardPoints || 0;
          return acc;
        },
        { blueChips: 0, goldChips: 0, rewardPoints: 0 }
      );
      if (
        totals.blueChips !== 0 ||
        totals.goldChips !== 0 ||
        totals.rewardPoints !== 0
      ) {
        return totals;
      }
    }
    return null;
  }, [data]);

  if (fetching) {
    return <></>;
  }

  return (
    <PopupContainer>
      <div
        className={`${styles.container} p-4 rounded shadow-xl border-blue-900 border-2`}
      >
        <div className={styles.innerContent}>
          <div className={styles.header}>
            <div className="text-center flex-1">
              <h1 className="text-3xl font-bold">
                {t("daily_activity_rewards_popup.title")}
              </h1>
            </div>
            {totalPaid && (
              <div className="ml-4">
                <Reward
                  currency={Currency.BlueChips}
                  amount={totalPaid.blueChips}
                />
                <Reward
                  currency={Currency.GoldChips}
                  amount={totalPaid.goldChips}
                />
                <Reward
                  currency={Currency.RewardPoints}
                  amount={totalPaid.rewardPoints}
                />
              </div>
            )}
          </div>
          <div className="mt-4">
            <div className={styles.rewards}>
              {data?.dailyActivityRewards?.map((item, idx) => (
                <Rewards item={item} idx={idx} />
              ))}
            </div>
          </div>
        </div>
        <div className="mt-4">
          <ButtonWithSound
            className="w-full rounded btn btn-blue-500 btn-lg"
            onClick={closePopup}
          >
            {t("close")}
          </ButtonWithSound>
        </div>
      </div>
    </PopupContainer>
  );
};

export function DailyActivityRewardPopup() {
  const { isPopupOpened } = useDailyActivityRewards();

  return <>{isPopupOpened && <Popup />}</>;
}
