import * as React from "react";
import { useFacebook } from "./Context";
import queryString from "query-string";
import * as Sentry from "@sentry/browser";

export function useFacebookLogin(): [
  boolean,
  (options: fb.LoginOptions) => Promise<fb.StatusResponse>,
  { [key: string]: string }
] {
  const [ready] = useFacebook();
  const extra = React.useMemo(() => {
    const params = queryString.parse(document.location.search);
    let requestIds = params["request_ids"];
    if (typeof requestIds === "string" && requestIds.trim().length > 0)
      requestIds = requestIds.trim();
    else requestIds = "";
    return {
      request_ids: requestIds,
    };
  }, []);
  const login = React.useCallback(
    (options?: fb.LoginOptions) => {
      return new Promise<fb.StatusResponse>((resolve, reject) => {
        if (!ready) {
          Sentry.captureMessage("Facebook Sdk is not loaded");
          reject("Sdk is not loaded");
        } else {
          const internalLogin = () => {
            FB.login((response) => {
              resolve(response);
            }, options);
          };
          FB.getLoginStatus(function (response) {
            if (response.status === "connected") {
              //If we have custom scopes check to see that we have asked the user before for those scopes.
              // This is to handle cases we add a new scope
              if (options && options.scope) {
                const permissions = options.scope.split(",");
                FB.api<
                  | {
                      data: Array<{
                        permission: string;
                        status: "granted" | "declined";
                      }>;
                    }
                  | {
                      error: string;
                    }
                >(
                  `${response.authResponse.userID}/permissions`,
                  (permissionsResponse) => {
                    let shouldLogin: boolean;
                    if ("error" in permissionsResponse) {
                      Sentry.captureMessage(
                        "Error requesting facebook permissions",
                        {
                          extra: { permissionsResponse },
                        }
                      );
                      console.error(
                        "Error requesting permissions.",
                        permissionsResponse
                      );
                      shouldLogin = true;
                    } else {
                      const notFound = permissions.filter((permission) => {
                        return (
                          permissionsResponse.data.find(
                            ({ permission: currentPermission }) => {
                              return currentPermission === permission;
                            }
                          ) === undefined
                        );
                      });
                      shouldLogin = notFound.length > 0;
                    }
                    if (!shouldLogin) resolve(response);
                    else internalLogin();
                  }
                );
              } else
                FB.logout(() => {
                  internalLogin();
                });
            } else internalLogin();
          });
        }
      });
    },
    [ready]
  );

  return [ready, login, extra];
}
