import * as React from "react";
import { Trans } from "react-i18next";

export const BalanceConsecutiveActivityRewardDetails = () => {
  return (
    <Trans i18nKey="transaction.BalanceConsecutiveActivityRewardDetails_details">
      Daily Reward
    </Trans>
  );
};
