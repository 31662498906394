import * as React from "react";
import { getIsLoggedAsGuest } from "../../guestId";
import { useAuthToken } from "../../hooks/useAuthToken";
import { useGuestId } from "../../hooks/useGuestId";
import { useReferral } from "../../hooks/useReferral";
import { useAskSendEmails } from "../AskSendEmails";
import { LOCAL_STORAGE_KEY } from "./ProductionLogin/email/AssignPassword";
import { removeFromLocalStorage } from "./utils";
import * as Sentry from "@sentry/browser";
import { useLeadSource } from "../../hooks/useLeadSource";

export const useLogin = (provider: string, portal: string) => {
  const [, setToken] = useAuthToken();
  const [, setGuestId, , , setIsLoggedAsGuest] = useGuestId();
  const [referral, , clearReferral] = useReferral();
  const [leadSource, , clearLeadSource] = useLeadSource();
  const { setDoAskSendEmailsOnLogin } = useAskSendEmails();
  return React.useCallback(
    (token: string, extra: { [key: string]: string } = {}) => {
      const data = new URLSearchParams();
      data.append("provider", provider);
      data.append("portal", portal);
      data.append("tenant", "mpirimpa");
      data.append("token", token);
      data.append(
        "referral",
        referral !== null && referral.trim().length > 0 ? referral.trim() : ""
      );
      if (leadSource && leadSource.trim().length > 0) {
        data.append("leadSource", leadSource.trim());
      }

      for (const [key, value] of Object.entries(extra)) data.append(key, value);

      return fetch(`${process.env.REACT_APP_API_HOST}/auth`, {
        method: "POST",
        body: data,
      })
        .then((fetchResult) => {
          if (!fetchResult.ok) {
            Sentry.captureMessage("Error authenticating", {
              extra: { request: data, response: fetchResult },
            });
            if (getIsLoggedAsGuest() && fetchResult.status === 500) {
              return Promise.reject();
            }
          } else return fetchResult.json();
        })
        .then((resp) => {
          setDoAskSendEmailsOnLogin(true);
          clearReferral();
          clearLeadSource();
          removeFromLocalStorage(LOCAL_STORAGE_KEY);
          setToken(resp.accessToken);
          if (resp.guestId) {
            setGuestId(resp.guestId);
            setIsLoggedAsGuest(true);
          } else {
            setIsLoggedAsGuest(false);
          }
          return resp.accessToken;
        });
    },
    [
      clearReferral,
      provider,
      portal,
      referral,
      setToken,
      setDoAskSendEmailsOnLogin,
      setGuestId,
      setIsLoggedAsGuest,
      leadSource,
    ]
  );
};
