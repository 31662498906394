import * as React from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { AbsoluteCenter } from "../../AbsoluteCenter";
import { useFacebook, useFacebookLogin } from "../../Facebook";
import { Loader } from "../../Loader";
import { useLogin } from "../useLogin";
import * as Sentry from "@sentry/browser";

const NAME = "facebook";

const Login = () => {
  const { t } = useTranslation();
  const login = useLogin(NAME, "fb_canvas");
  const [ready, fbLogin, extra] = useFacebookLogin();
  const [completed, setCompleted] = React.useState(false);
  const initiated = React.useRef(false);

  React.useLayoutEffect(() => {
    if (initiated.current || !ready) return;
    let current = true;
    const initiate = async () => {
      try {
        const result = await fbLogin({ scope: "email" });
        const { status, authResponse } = result;
        if (status !== "connected") {
          console.error("Wrong status", result);
          Sentry.captureMessage("Wrong status on canvas facebook login", {
            extra: { result },
          });
          return;
        }

        const { accessToken } = authResponse;
        await login(accessToken, extra);
        if (current) setCompleted(true);
      } catch (e) {
        console.error("Error authenticating", e);
        Sentry.captureMessage("Error authenticating with facebook canvas", {
          extra: { e },
        });
      }
    };

    initiated.current = true;
    initiate();
    return () => {
      current = false;
    };
  }, [extra, fbLogin, login, ready]);
  return completed ? (
    <Redirect to="/" />
  ) : (
    <AbsoluteCenter>
      <Loader description={t("authenticating")} />
    </AbsoluteCenter>
  );
};

export const FacebookCanvasLogin = () => {
  const [, isFacebookCanvas] = useFacebook();
  return !isFacebookCanvas ? <Redirect to="/" /> : <Login />;
};
