import {
  Client,
  createClient,
  dedupExchange,
  fetchExchange,
  subscriptionExchange,
} from "urql";
import { devtoolsExchange } from "@urql/devtools";
import { cacheExchange } from "@urql/exchange-graphcache";
import { multipartFetchExchange } from "@urql/exchange-multipart-fetch";
import { contextExchange } from "./contextExchange";
import { SubscriptionClientWithStatus } from "../other/subscriptionClientWithStatus";
import schema from "../graphql/introspection.json";
import { i18n } from "i18next";
import { me } from "./updates/me";
import { joinRoom } from "./updates/joinRoom";
import { sendBalance } from "./updates/sendBalance";
import { relayPagination } from "@urql/exchange-graphcache/extras";
import { createFriendRequest } from "./updates/createFriendRequest";
import { createBan } from "./updates/createBan";
import { cancelBan } from "./updates/cancelBan";
import { answerFriendRequest } from "./updates/answerFriendRequest";
import { cancelFriendship } from "./updates/cancelFriendship";
import { useAvatar } from "./updates/useAvatar";
import { getAccessToken } from "../authToken";
import { unauthorizedExchange } from "./unauthorizedExchange";
import { purchaseEntry } from "./updates/purchaseEntry";
import { watchedAds } from "./updates/watchedAds";

const HOST = process.env.REACT_APP_API_HOST!;

export const makeClient = (
  i18nInstance: i18n
): [SubscriptionClientWithStatus, Client, () => void] => {
  const token = getAccessToken();
  const [scheme, hostname] = HOST.split("://");
  const subscriptionClient = new SubscriptionClientWithStatus(
    `${scheme === "https" ? "wss" : "ws"}://${hostname}/graphql`,
    {
      reconnect: false,
      minTimeout: 5000,
      timeout: 300000,
      connectionParams: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  const urqlClient = createClient({
    url: `${HOST}/graphql`,
    exchanges: [
      dedupExchange,
      process.env.NODE_ENV !== "production"
        ? devtoolsExchange
        : ({ forward }) => forward,
      cacheExchange({
        resolvers: {
          Query: {
            table: (_, args: any) => ({ __typename: "Table", id: args.id }),
            room: (_, args: any) => ({ __typename: "Room", id: args.id }),
          },
          User: {
            activities: relayPagination(),
          },
        },
        updates: {
          Subscription: {
            joinRoom,
            me,
          },
          Mutation: {
            sendBalance,
            createFriendRequest,
            createBan,
            answerFriendRequest,
            cancelFriendship,
            cancelBan,
            useAvatar,
            purchaseEntry,
            watchedAds,
          },
        },
        keys: {
          LeaderboardPrice: () => null,
          TournamentPrice: () => null,
          ScratchCardMask: () => null,
          ScratchCardCombination: () => null,
          ScratchCardDimensions: () => null,
          MpirimpaDefaultConfig: () => null,
          MpirimpaDefaultConfigTuple: () => null,
          TicTacToeDefaultConfig: () => null,
          TicTacToeDefaultConfigTuple: () => null,
          TichuDefaultConfig: () => null,
          TichuDefaultConfigTuple: () => null,
          ScratchCardGrid: () => null,
          Amount: () => null,
          GameStatistics: () => null,
          BalanceUpdateSendActivityContent: () => null,
          FriendRequestActivityContent: () => null,
          Activities: () => null,
          CursorPageInfo: () => null,
          FriendRequest: () => null,
          FriendRequestAcceptedActivityContent: () => null,
          FriendShip: () => null,
          BannedUsers: () => null,
          BannedUser: () => null,
          Tables: () => null,
          FriendshipCanceledActivityContent: () => null,
          FriendshipType: () => null,
          BanningType: () => null,
          UserMessageContent: () => null,
          PurchaseActivityContent: () => null,
          BalanceUpdateLeaderBoardAwardContent: () => null,
          Friends: () => null,
          ReferConfig: () => null,
          UserReferContent: () => null,
          Transactions: () => null,
          BalanceUpdateTableDetails: () => null,
          BalanceUpdateAvatarPurchaseDetails: () => null,
          BalanceUpdateVirtualItemGiftDetails: () => null,
          BalanceUpdateVirtualItemGiftInChatDetails: () => null,
          BalanceUpdateSendToUserDetails: () => null,
          BalanceUpdateReceivedFromUserDetails: () => null,
          BalanceUpdateScratchCardWinDetails: () => null,
          BalanceUpdateScratchCardPurchaseDetails: () => null,
          BalanceUpdateReferDetails: () => null,
          BalanceUpdateReferredByDetails: () => null,
          BalanceUpdateWelcomeDetails: () => null,
          BalanceUpdatePurchaseDetails: () => null,
          BalanceUpdateLeaderBoardAwardDetails: () => null,
          BalanceUpdateRollbackDetails: () => null,
          BalanceUpdateSystemGiftDetails: () => null,
          BalanceUpdateAdjustmentDetails: () => null,
          BalanceUpdateAdjustmentActivityContent: () => null,
          BalanceUpdateTopupDetails: () => null,
          BalanceUpdateTournamentAwardContent: () => null,
          BalanceUpdateTournamentAwardDetails: () => null,
          BalanceUpdateTournamentPurchaseDetails: () => null,
          BalanceUpdateRewardedAdDetails: () => null,
          CanSendChipsLimit: () => null,
          PlayerStatistics: () => null,
          OpenLeaderboardPrice: () => null,
          HangmanDefaultConfig: () => null,
          CommissionConfig: () => null,
          HangmanDefaultConfigTuple: () => null,
          HangmanSinglePlayer: () => null,
          HangmanMultiPlayer: () => null,
          HangmanChallenge: () => null,
          HangmanSettings: () => null,
          HangmanBetByNumberOfRoundsConfig: () => null,
          ChallengeDailyStatistic: () => null,
          ChatUsers: () => null,
          Ads: () => null,
          PlacementConfig: () => null,
          RewardsConfig: () => null,
          MainScreenItemSettingValue: () => null,
          MpirimpaBetByPointsConfig: () => null,
          TichuBetByPointsConfig: () => null,
          DailyActivityRewardItem: () => null,
          DailyActivityReward: () => null,
        },
        schema: schema as any,
      }),
      contextExchange(i18nInstance),
      unauthorizedExchange(),
      //@ts-ignore
      multipartFetchExchange,
      subscriptionExchange({
        forwardSubscription: (operation) => {
          const {
            context: { fetchOptions },
          } = operation;

          const extraOptions =
            typeof fetchOptions === "function"
              ? fetchOptions()
              : fetchOptions || {};
          return subscriptionClient.request({
            ...operation,
            context: extraOptions,
          });
        },
        enableAllOperations: process.env.NODE_ENV === "production",
      }),
      fetchExchange,
    ],
  });
  return [
    subscriptionClient,
    urqlClient,
    () => {
      subscriptionClient.close();
    },
  ];
};
