import * as React from "react";
import { useLocalStorage } from "./useStorage";
export const useLeadSource = (): [
  string | null,
  (leadSource: string) => void,
  () => void
] => {
  const [leadSource, setLeadSource, reset] = useLocalStorage<string | null>(
    "leadSource",
    null
  );

  const setValue = React.useCallback(
    (leadSource: string) => setLeadSource(leadSource),
    [setLeadSource]
  );

  return React.useMemo(() => [leadSource, setValue, reset], [
    leadSource,
    setValue,
    reset,
  ]);
};
