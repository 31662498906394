import React from "react";
import { useLeadSource } from "../../hooks/useLeadSource";
type LoginType = {
  email: string;
  password: string;
};
type SignUpType = {
  email: string;
  userName: string;
};

type SignUpConfirmType = {
  otpCode: string;
  password: string;
  otpId: string;
  leadSource?: string | null;
};

export const emailSignInRequest = ({ email, password }: LoginType): any => {
  const url = `${process.env.REACT_APP_API_HOST!}/auth`;
  const data = new URLSearchParams();
  data.append("provider", "email");
  data.append("portal", "website");
  data.append("tenant", "mpirimpa");
  data.append("email", email);
  data.append("password", password);
  return fetch(url, {
    method: "POST",
    body: data,
  })
    .then((response) => {
      if (!response.ok) {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }
      return response.json();
    })
    .then((body) => {
      return body.accessToken;
    })
    .catch((error) => {
      return error.message;
    });
};

const postRequest = ({
  url,
  data,
  expectEmptyBody,
}: {
  url: string;
  data: URLSearchParams;
  expectEmptyBody?: boolean;
}): Promise<any> => {
  return fetch(url, {
    method: "POST",
    body: data,
  }).then((response) => {
    if (!response.ok) {
      return response.text().then((text) => {
        throw new Error(text);
      });
    }
    if (expectEmptyBody) return {};
    return response.json();
  });
};

export const assignPasswordRequest = (
  email: string
): Promise<{
  error: string | undefined;
  otpId: string | undefined;
  cause: string | undefined;
}> => {
  const url = `${process.env.REACT_APP_API_HOST!}/request-assign-password`;
  const data = new URLSearchParams();
  data.append("email", email);

  return postRequest({ url, data })
    .then((body) => {
      return { error: undefined, otpId: body.otpId, cause: body.cause };
    })
    .catch((error) => {
      return { error: error.message, otpId: undefined, cause: undefined };
    });
};

export const finishAssignPasswordRequest = ({
  otp,
  otpId,
  password,
}: {
  otp: string;
  otpId: string;
  password: string;
}): Promise<{ error: string | null }> => {
  const url = `${process.env.REACT_APP_API_HOST!}/finish-assign-password`;
  const data = new URLSearchParams();
  data.append("otp", otp.toString());
  data.append("otpId", otpId);
  data.append("password", password);

  return postRequest({
    url,
    data,
    expectEmptyBody: true,
  })
    .then(() => {
      return { error: null };
    })
    .catch((error) => {
      return { error: error.message };
    });
};

export const signUpRequest = ({ email, userName }: SignUpType): any => {
  const url = `${process.env.REACT_APP_API_HOST!}/signup/request-sign-up`;
  const data = new URLSearchParams();
  data.append("email", email);
  data.append("name", userName);
  return fetch(url, {
    method: "POST",
    body: data,
  })
    .then((response) => {
      if (!response.ok) {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }
      return response.json();
    })
    .then((body) => {
      return body.otpId;
    })
    .catch((error) => {
      return error.message;
    });
};

export const signUpConfirmRequest = ({
  password,
  otpCode,
  otpId,
  leadSource,
}: SignUpConfirmType): any => {
  const url = `${process.env.REACT_APP_API_HOST!}/signup/finish-sign-up`;
  const data = new URLSearchParams();
  data.append("password", password);
  data.append("otp", otpCode);
  data.append("otpId", otpId);
  data.append("portal", "website");
  if (leadSource && leadSource.trim().length > 0) {
    data.append("leadSource", leadSource.trim());
  }

  return fetch(url, {
    method: "POST",
    body: data,
  })
    .then((response) => {
      if (!response.ok) {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }
      return true;
    })
    .catch((error) => {
      return error.message;
    });
};

export const resetPasswordRequest = ({
  email,
}: {
  email: string;
}): Promise<{
  error: string | undefined;
  otpId: string | undefined;
  cause: string | undefined;
}> => {
  const url = `${process.env.REACT_APP_API_HOST!}/request-reset-password`;
  const data = new URLSearchParams();
  data.append("email", email);

  return postRequest({ url, data })
    .then((body) => {
      return { error: undefined, otpId: body.otpId, cause: body.cause };
    })
    .catch((error) => {
      return { error: error.message, otpId: undefined, cause: undefined };
    });
};

export const resetPasswordConfirmRequest = ({
  password,
  otpCode,
  otpId,
}: SignUpConfirmType): any => {
  const url = `${process.env.REACT_APP_API_HOST!}/finish-reset-password`;
  const data = new URLSearchParams();
  data.append("password", password);
  data.append("otp", otpCode);
  data.append("otpId", otpId);
  return fetch(url, {
    method: "POST",
    body: data,
  })
    .then((response) => {
      if (!response.ok) {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }
      return true;
    })
    .catch((error) => {
      return error.message;
    });
};
